import React from "react";
import LutelyLogoImage from "../../icons/lib/lutely-logo";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import Link from "next/link";
import AuthButtons from "./auth-buttons";
import CartButton from "./cart-button";
import MobileNavbar from "./mobile-navbar";
import LogoutConfirmationModal from "./logout-confirmation-modal";
import SearchInputModalContainer from "./search-input-modal-container";
import { navbarCategoryAtom } from "@/lib/atoms/linked-filters";
import TransparentButton from "@/components/buttons/transparent-button";
import AppLink from "@/components/app-link";

export type NavbarProps = ClassProps;

/**
 * Navbar component which has an independent FilterProvider context to perform
 * searches that don't affect other parts of the page. It is intended to be used
 * in the general layout of the site.
 *
 * In addition to it, it will allow to maintain the category synchronized by
 * using a {@link navbarCategoryAtom}.
 */
const Navbar = ({ className }: NavbarProps) => {
  return (
    <div className={cx("fixed w-full z-[1000]", className)}>
      <div
        className={cx(
          "h-[70px] bg-darkGreen px-14 ps-12 flex-row items-center justify-between w-full gap-[40px]",
          "hidden lg:flex"
        )}
      >
        <div className="flex flex-row items-center gap-[20px]">
          <Link href="/">
            <LutelyLogoImage className="flex-shrink-0 cursor-pointer" />
          </Link>

          {/* Divider */}
          <span className="h-8 w-[1px] bg-white bg-opacity-20"></span>

          {/* Marketplace button */}
          <AppLink href="/marketplace?category=all">
            <TransparentButton className="!px-0 font-righteous">Marketplace</TransparentButton>
          </AppLink>
        </div>

        {/* Search bar container */}
        <SearchInputModalContainer />

        {/* Right buttons */}
        <div className="flex items-center">
          <CartButton />
          <AuthButtons />
        </div>
      </div>

      <MobileNavbar />

      <LogoutConfirmationModal />
    </div>
  );
};

export default Navbar;
