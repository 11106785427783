import { BaseEntityType } from "@/models/enums";

export const getCategoryStringFromBaseEntityType = (
  baseEntityType: BaseEntityType
) => {
  switch (baseEntityType) {
    case BaseEntityType.beat:
      return "beats";
    case BaseEntityType.soundkit:
      return "soundkits";
    case BaseEntityType.creator:
      return "creators";
    case BaseEntityType.link:
      return "links";
    case BaseEntityType.song:
      return "songs";
  }
};
