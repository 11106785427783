import { ReactNode } from "react";
import { AvailableViews } from "../middleware";
import { FilterType } from "../models/enums";
import { Right } from "../data/licenses";
import { StringIdentifiableOption } from "@/lib/types/string-identifiable-option";

export type EntityType = "beats" | "songs" | "soundkits";

export type FilterContextEntityType = "beats" | "songs" | "sounds" | "creators";

export type Filter = {
  id?: string;
  type: FilterType;
  name: string;
  description?: string;
};

type GenreData = {
  subGenres: Filter[];
};
export type Genre = Filter & GenreData;

type MoodData = {
  relatedMoods: Filter[];
  related_moods: Filter[];
};
export type Mood = Filter & MoodData;

type SubGenreData = {
  genreId: string;
};

export type SubGenre = Filter & SubGenreData;

type KeyData = {
  isMajor: boolean;
};
export type Key = Filter & KeyData;

export type CustomerInfo = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
} | null;

export type PaymentInfo = {
  cardNumber: string;
  cardName: string;
  expirationDate: string;
  cvv: string;
} | null;

export type ViewObject = {
  id: string;
  type: AvailableViews;
  name: string;
  price?: number;
  artist?: Artist;
  artists?: {
    artist: Artist;
    artist_id: string;
    song_id?: string;
    beat_id?: string;
    soundkit_id?: string;
    id: string;
  }[];
  // TODO: need to review if this property is still used by the backend
  keySignature?: string;
  key?: string;
  genres?: Genre | null;
  styles: SubGenre[];
  moods: Mood[];
  tempo: number;
  sample?: string;
  soundsLike?: string[];
  waveForms?: {
    desktopUrl: string;
    mobileUrl: string;
  };
  publishDate?: string;
  status?: string;
};

type BeatData = {
  cover: string | null;
  pictureUrl: string;
  duration: number;
  licenseRights: LicenseRight[];
  tag?: Tag;
  vocals?: string;
  lyrics?: string;
  files?: any;
  audio_preview?: string;
};

export type Beat = ViewObject & BeatData;

type SoundKitData = {
  description: string;
  cover: string | null;
  pictureUrl: string;
  royaltyFree: boolean;
  tag?: Tag;
  types: Filter[];
  isFeatured?: boolean;
  file?: {
    id: string;
    name: string;
    size: number;
  };
  is_royalty_free?: boolean;
};

export type SoundKit = ViewObject & SoundKitData;

export type Artist = {
  id: string;
  type: AvailableViews;
  name: string;
  firstName: string;
  lastName: string;
  profilePicUrl: string;
  bio: string;
  location?: Location;
  createdAt: string;
  socialMedia?: SocialMediaType;
  beats?: Beat[];
};

export type BeatArtist = {
  id: string;
  beat_id: string;
  artist_id: string;
  beat: Beat;
};

export type FeaturedArtist = {
  id: string;
  type: AvailableViews;
  name: string;
  firstName: string;
  lastName: string;
  profilePicUrl: string;
  bio: string;
  location?: Location;
  createdAt: string;
  socialMedia?: SocialMediaType;
  beats?: Beat[];
};

export type Feature = {
  id: number;
  icon: ReactNode;
  title: string;
  subtitle: string;
};

export type SearchCategory = {
  // id: number;
  name: "Beats" | "Sounds" | "Creators" | "Songs";
  icon: ReactNode;
  backgroundColor?: string;
  color?: string;
};

export type CartItem = {
  id: string;
  name: string;
  productType: string;
  cover: string | null;
  price?: number;
  licenseType?: string;
  licenseRights?: Right;
  format?: string | number;
  distribution?: string;
  addons?: string[];
  fullItem: Beat | SoundKit;
};

export type RecentSearch = {
  id: number;
  name: string;
};

export type Tag = {
  name: string;
  textColor: string;
  backgroundColor: string;
};

export type SocialMediaType = {
  soundcloud?: string;
  youtube?: string;
  tiktok?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  x?: string;
};

export type Location = {
  id?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
};

export type LicenseRight = {
  id: string;
  price: string;
  unlimitedDistribution: string;
  monetizeMusic: string;
  monetize_music?: string;
  name: Right;
  files: FileType[];
  license_right: string | number;
};

export type FileType = {
  id: string;
  url?: string;
  extraPrice: string;
  type: number | string;
};

export type DetailedOrderItem = {
  id: string;
  price: string;
  beat_id: string | null;
  song_id: string | null;
  soundkit_id: string | null;
  beat?: Beat | null;
  song: Beat | null;
  soundkit?: SoundKit | null;
  file_license?: {
    extra_price: string;
    file: FileType | null;
    file_id: string;
    id: string;
    license_right: LicenseRight | null;
    license_right_id: string;
  };
  license_right: LicenseRight | null;
  file: FileType | null;
  addons: string[] | null;
  unlimited_distribution: boolean;
  type: "beats" | "songs" | "soundkits";
  order_email: string;
  order_provider: any;
  metadata: any;
  created_at?: Date | null;
  order?: Order;
};

export type Order = {
  id: string;
  first_name: string;
  last_name: string;
  address: string;
  payment: any;
  total: number;
  status: string;
  items: DetailedOrderItem[];
  provider: string;
  provider_id: string;
  created_at: string;
};

export type OrderItem = {
  id: string;
  beat_id: string | null;
  song_id: string | null;
  soundkit_id: string | null;
  license_right_id: string | null;
  file_id: string | null;
  file_license: LicenseRight;
  addons: string[] | null;
  unlimited_distribution: boolean;
  order?: Order;
};

export type SalesSummary = {
  earnings: number;
  licensesSold: number;
  customers: number;
};

export type ResponsiveSizes = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export type CarouselItemsPerRow = { [key in ResponsiveSizes]: number };

export type MarketplaceSearchCategoryId = "all" | "beats" | "songs" | "sounds";

export type MarketplaceSearchCategory = {
  id: MarketplaceSearchCategoryId;
  name: string;
  icon?: ReactNode;
};

export type IdentifiableOption = {
  id: number;
  name: string;
};

export type GenericResponse = {
  ok: boolean;
  message: string;
};

export type PaginationResponse<T> = {
  data: T[];
  limit: number;
  offset: number;
  total: number;
};

export type VocalsType =
  | "vocals-male"
  | "vocals-female"
  | "vocals-male-female"
  | "vocals-none";

export const vocalsMap: Record<VocalsType, number> = {
  "vocals-male": 0,
  "vocals-female": 1,
  "vocals-male-female": 2,
  "vocals-none": 3,
};

export enum StatusType {
  Published = "Published",
  Pending = "Pending",
  Draft = "Draft",
  Private = "Private",
  Purchased = "Purchased",
  Processing = "Processing",
  Deleted = "Deleted",
}
