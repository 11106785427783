import { useGenres } from "@/lib/genres-manager";
import { parseSearchCategory } from "@/lib/helpers";
import { useKeys } from "@/lib/keys-manager";
import { useMoodsFilters } from "@/lib/moods-manager";
import { useKinds } from "@/lib/soundkit-kinds-manager";
import { BaseEntityType } from "@/models/enums";
import { Genre, SearchCategory } from "@/models/models";
import { PropsWithChildren, createContext } from "react";

export type UseGenresContextParams = {
  category: BaseEntityType | null;
  selectedCategory?: BaseEntityType;
};

export type UseFilterDataContextProps = {
  category?: SearchCategory | null;
  selectedGenre?: Genre | null;
  /**
   * Indicates whether the array should include the Any option.
   */
  includeAny?: boolean;
};

/**
 * This hook manages the moods, keys and kinds by calling the respective
 * endpoints only once by session. Genre and subgenres are also cached and we
 * obtain the genre and subgenres by songs and beats only when required.
 */
const useFilterDataContext = ({
  category,
  selectedGenre,
  includeAny,
}: UseFilterDataContextProps) => {
  const { genres, subGenres } = useGenres(
    parseSearchCategory(category),
    selectedGenre?.id,
    includeAny
  );
  const { moods } = useMoodsFilters({ includeAny });
  const { keys } = useKeys({ includeAny });
  const { kinds } = useKinds({ includeAny });

  return {
    genres,
    subGenres,
    moods,
    keys,
    kinds,
  };
};

const FilterDataContext = createContext(undefined);

export const FilterDataProvider = ({ children }: PropsWithChildren) => {
  useFilterDataContext({});

  return (
    <FilterDataContext.Provider value={undefined}>
      {children}
    </FilterDataContext.Provider>
  );
};

export default useFilterDataContext;
